var ce = function(elDataStr, elContent, elProps) {
  elData = elDataStr.split('#').join('.').split('.');
  var el = document.createElement(elData[0]);
  if (elData[1] && elDataStr.indexOf('#') !== -1) el.id = elData[1];
  if (elData[2] || elData[1] && elDataStr.indexOf('.') !== -1) el.className = elData[2] || elData[1];

  if (elProps) {
    for (prop in elProps) {
      el[prop] = elProps[prop];
    }
  }

  if (elContent) {
    if (typeof elContent === 'string') el.innerHTML = elContent;
    else {
      elContent = elContent.length ? elContent : [elContent];
      for (var i = 0; i < elContent.length; i++) {
        el.appendChild(elContent[i]);
      }
    }
  }
  return el;
};

/*
window.addEventListener("error", function(err) {
  var hasRun = false;
  if (!hasRun && err.target && err.target.nodeName == 'SCRIPT' && err.target.src.replace(/^http(s|):\/\//,'').indexOf(window.location.hostname + '/app/') === 0) {
    hasRun = true;
    document.body.appendChild(
      ce(
        'div#err',
        ce(
          'div#msg',
          [
            ce('h3', 'Something Went Wrong...'),
            ce('div',
              [
                ce('p', 'Refreshing your browser usually helps.'),
                ce('p',
                  ce('a.mat-button mat-flat-button mat-primary', 'Refresh Browser', {href: 'javascript:location.reload(true)'})
                )
              ])
          ]
        )
      )
    );
  }
}, true);
*/

function getInternetExplorerVersion()
{
  var rv = -1;
  if (navigator.appName == 'Microsoft Internet Explorer')
  {
    var ua = navigator.userAgent;
    var re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  else if (navigator.appName == 'Netscape')
  {
    var ua = navigator.userAgent;
    var re  = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  return rv;
}

if (document.cookie.indexOf("ieWarningIgnored") === -1 && getInternetExplorerVersion() >= 0) {
  rm = function() {
    document.cookie = "ieWarningIgnored=true";
    document.getElementById('err').style.display = 'none';
  }

  document.body.appendChild(
    ce(
      'div#err',
      ce(
        'div#msg',
        [
          ce('h3', 'Upgrade Your Browser'),
          ce('div',
            [
              ce('p', 'Internet Explorer is an older browser and modern websites don\'t always work perfectly using it.'),
              ce('p', 'Please download a modern browser such as <a href="https://www.google.com/chrome/">Chrome</a> or <a href="https://www.mozilla.org/en-US/firefox/">FireFox</a> for a better user experience.'),
              ce('p',
                [
                  ce('a.mat-button mat-flat-button mat-primary gap', 'Download Chrome', {href: 'javascript:location.href="https://www.google.com/chrome/";'}),
                  ce('a.mat-button mat-flat-button mat-secondary', 'No Thanks', {href: 'javascript:rm();'})
                ]
              )
            ])
        ]
      )
    )
  );
}
